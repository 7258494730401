<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="technicalSupportHelpTreeNodes.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('TechnicalSupportHelpTree')"
    @cancel="onCancel('TechnicalSupportHelpTree')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'TechnicalSupportHelpTreeCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'TechnicalSupportHelpTree',
      pageHeader: 'Создание дерева решений по тех. поддержке',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Дерево решений по тех. поддержке',
          route: { name: 'TechnicalSupportHelpTree' },
        },
        {
          text: 'Создание дерева решений по тех. поддержке',
        },
      ],
      initialForm: {
        name: null,
        parentId: null,
        isConclusion: false,
        conclusion: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      technicalSupportHelpTreeNodes: (state) =>
        state.common.technicalSupportHelpTreeNodes,
    }),

    fields() {
      return [
        {
          form: [
            { type: 'text', key: 'name', label: 'Текст варианта выбора' },
            {
              type: 'select',
              key: 'parentId',
              label: 'Родительский узел для этой записи',
              list: [
                { name: 'Не выбрано', value: null },
                ...this.technicalSupportHelpTreeNodes.data,
              ],
            },
            {
              type: 'checkbox',
              key: 'isConclusion',
              label: 'Является ли элемент решением',
            },
            {
              type: 'textarea',
              key: 'conclusion',
              label: 'Текст решения, если элемент является решением',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.getCommonList({ name: 'TechnicalSupportHelpTreeNodes' });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
